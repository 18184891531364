import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hello.
    </Text>
    <p>{`I'm Marcus.`}</p>
    <p>{`I'm inspired by ideas that make humans better.`}</p>
    <p>{`I'm currently thinking alot about the new interface to information enabled through AI.`}</p>
    <ul>
      <li parentName="ul">{`How we can we empower everyone on the planet to make decisions at the speed of the internet?`}</li>
      <li parentName="ul">{`What new workflows will AI enable?`}</li>
      <li parentName="ul">{`How can AI act as a partner in co-creating the future we hope for?`}</li>
    </ul>
    <p>{`My end goal is to help humans... be human.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      